import React from "react";

const ClientsData = (props) => {
  const { note, index } = props;
  return (
    <>
      <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
        <td className="px-4 py-2 border border-gray-400">{index+1}</td>
        <td className="px-4 py-2 border border-gray-400">{note.name}</td>
      </tr>
    </>
  );
};

export default ClientsData;
