import { createContext, useContext, useState } from "react";
import * as XLSX from "xlsx";
const NoteContext = createContext();

const NoteState = (props) => {
  const host = "https://serverbackend-rx46.onrender.com"

  const notesInitial = [];
  const [notes, setNotes] = useState(notesInitial);
  const [data, setData] = useState([]);
  //GET all Notes
  const getNotes = async () => {
    const response = await fetch(`${host}/api/notes/fetchallnotes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      }
    });
    const json = await response.json();
    console.log(json);
    setNotes(json);
  };

  const followUp = async () => {
    const response = await fetch(`${host}/api/notes/fetchallnotes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    const newNotes = json.filter((note) => {
      return note.status === "follow_up";
    });
    console.log(newNotes);
    setNotes(newNotes);
  };

  const onHold = async () => {
    const response = await fetch(`${host}/api/notes/fetchallnotes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    const newNotes = json.filter((note) => {
      return note.status === "on_hold";
    });
    console.log(newNotes);
    setNotes(newNotes);
  };

  const noResponse = async () => {
    const response = await fetch(`${host}/api/notes/fetchallnotes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    const newNotes = json.filter((note) => {
      return note.status === "no_response";
    });
    console.log(newNotes);
    setNotes(newNotes);
  };

  const completed = async () => {
    const response = await fetch(`${host}/api/notes/fetchallnotes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    const newNotes = json.filter((note) => {
      return note.status === "completed";
    });
    console.log(newNotes);
    setNotes(newNotes);
  };
  //Add a Note
  const addNote = async (
    name,
    email,
    phoneNumber,
    linkedInProfile,
    company,
    position,
    notess,
    status
  ) => {
    //API call
    const response = await fetch(`${host}/api/notes/addnote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name,
        email,
        phoneNumber,
        linkedInProfile,
        company,
        position,
        notess,
        status,
      }),
    });
    const note = await response.json();
    setNotes([...notes, note]);
    console.log(note);
  };

  //Delete a Note
  const deleteNote = async (id) => {
    //API call
    const response = await fetch(`${host}/api/notes/deletenote/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    console.log(json);

    const newNotes = notes.filter((note) => {
      return note._id !== id;
    });
    setNotes(newNotes);
  };

  //Edit Note
  const editNote = async (
    id,
    name,
    email,
    phoneNumber,
    linkedInProfile,
    company,
    position,
    notess,
    status
  ) => {
    //API call
    const response = await fetch(`${host}/api/notes/updatenote/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        name,
        email,
        phoneNumber,
        linkedInProfile,
        company,
        position,
        notess,
        status,
      }),
    });
    const json = await response.json();
    console.log(json);

    const newNotes = JSON.parse(JSON.stringify(notes));
    //LOGIC
    for (let index = 0; index < newNotes.length; index++) {
      const element = newNotes[index];
      if (element._id === id) {
        newNotes[index].name = name;
        newNotes[index].email = email;
        newNotes[index].phoneNumber = phoneNumber;
        newNotes[index].linkedInProfile = linkedInProfile;
        newNotes[index].company = company;
        newNotes[index].position = position;
        newNotes[index].notess = notess;
        newNotes[index].status = status;
        break;
      }
    }
    setNotes(newNotes);
  };

  //send mail
  const addMail = async (email, message) => {
    //API call
    const response = await fetch(`${host}/api/notes/mail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({
        email,
        message,
      }),
    });
    const note = await response.json();
    console.log(note);
  };

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);
      console.log(parsedData);
      setData(parsedData);
    };
  };
  return (
    <NoteContext.Provider
      value={{
        notes,
        addNote,
        deleteNote,
        editNote,
        getNotes,
        addMail,
        followUp,
        onHold,
        noResponse,
        completed,
        handleFileUpload,data,host
      }}
    >
      {props.children}
    </NoteContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(NoteContext);
};

export { NoteState, useGlobalContext };
