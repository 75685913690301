import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar = () => {
  let naviagte = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    naviagte("/login");
  };

  return (
    <div>
      <nav className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <NavLink className="text-white font-bold text-xl">
              My Website
            </NavLink>
          </div>
          <div className="flex items-center">
            {!localStorage.getItem("token") ? (
              <form>
                <NavLink
                  to={"/signup"}
                  role="button"
                  className="text-white mr-4"
                >
                  Signup
                </NavLink>
                <NavLink
                  to={"/login"}
                  role="button"
                  className="text-white mr-4"
                >
                  Login
                </NavLink>
              </form>
            ) : (
              <>
                <NavLink to="/" className="text-white mr-4">
                  Home
                </NavLink>
                <NavLink to="/all" className="text-white mr-4">
                  Data
                </NavLink>
                <NavLink to="/clients" className="text-white mr-4">
                  Clients
                </NavLink>
                <NavLink to="/about" className="text-white mr-4">
                  About
                </NavLink>
                <NavLink to="/contact" className="text-white mr-4">
                  Contact
                </NavLink>
                <button
                  onClick={() => handleLogout()}
                  type="submit"
                  className="text-white mr-4"
                >
                  Logout
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
