import React, { useState } from "react";
import { useGlobalContext } from "../context/NoteState";
import Data from "./data";
const NoteItems = () => {
  const { addNote, data } = useGlobalContext();
  return (
    <>
       {data.map((course) => (
        <>
          <Data course={course}/>
        </>
      ))}
    </>
  );
};

export default NoteItems;


