import React, { useState } from "react";
import { useGlobalContext } from "../context/NoteState";

const AddNote = () => {
  const { addNote } = useGlobalContext();

  const [note, setNote] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    linkedInProfile: "",
    company: "",
    position: "",
    notess: "",
    status: "",
  });

  const handleClick = (e) => {
    e.preventDefault();
    addNote(
      note.name,
      note.email,
      note.phoneNumber,
      note.linkedInProfile,
      note.company,
      note.position,
      note.notess,
      note.status
    );
    setNote({
      name: "",
      email: "",
      phoneNumber: "",
      linkedInProfile: "",
      company: "",
      position: "",
      notess: "",
      status: "",
    });
  };

  const onChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div>
        <button
          onClick={openModal}
          className="  px-4 py-2.5 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Add Data
        </button>
        {/* Modal */}
        {isOpen && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div className="fixed inset-0 bg-black opacity-50"></div>
              <div className="relative bg-white rounded-md px-12 py-2 w-1/3">
                <button
                  onClick={closeModal}
                  className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  &times;
                </button>
                <h2 className="text-xl font-semibold mb-4">Add Note</h2>
                <form onSubmit={handleClick}>
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={note.name}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={note.email}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <input
                      type="number"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={note.phoneNumber}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      linkedIn Profile
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="linkedInProfile"
                      value={note.linkedInProfile}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="company"
                      value={note.company}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Position
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="position"
                      value={note.position}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Notes
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="notess"
                      value={note.notess}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>
                    <select
                      name="status"
                      value={note.status}
                      onChange={onChange}
                      className="mt-1 p-1 border border-gray-300 rounded-md w-full"
                      id="role-select"
                    >
                      <option value="follow_up">Follow Up</option>
                      <option value="no_response">No Response</option>
                      <option value="on_hold">On Hold</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div>

                  <div className="mt-4">
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddNote;
