import React, { useState } from "react";
import { useGlobalContext } from "../context/NoteState";
const Excel = () => {
  const { handleFileUpload } = useGlobalContext();
  return (
    <div>
      <input
        id="files"
        className="hidden"
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
      />
      <button className="  px-4 py-2.5 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
        <label for="files">Select file</label>
      </button>

    </div>
  );
};

export default Excel;
