import React, { useState } from "react";
import { useGlobalContext } from "../context/NoteState";
import Message from "./Message";

const NoteItem = (props) => {
  const { deleteNote, editNote } = useGlobalContext();
  const { note, updateNote, index, togglePopup} = props;
  const f1 = () => {
    updateNote(note);
  };
  const f2 = () => {
    togglePopup();
  };
  /*  */
  const {
    name,
    email,
    phoneNumber,
    linkedInProfile,
    company,
    position,
    notess,
    status,
  } = note;
  /* practice  */
  const [noted, setNote] = useState({
    id: note._id,
    ename: note.name,
    eemail: note.email,
    ephoneNumber: note.phoneNumber,
    elinkedInProfile: note.linkedInProfile,
    ecompany: note.company,
    eposition: note.position,
    enotess: note.notess,
    estatus: note.status,
  });

  const onChange = (e) => {
    setNote({ ...noted, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    console.log("Updating the note...", note);
    editNote(
      noted.id,
      noted.ename,
      noted.eemail,
      noted.ephoneNumber,
      noted.elinkedInProfile,
      noted.ecompany,
      noted.eposition,
      noted.enotess,
      noted.estatus
    );
  };
  return (
    <>
      <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
        <td className="px-4 py-2 border border-gray-400">{name}</td>
        <td className="px-4 py-2 border border-gray-400">{email}</td>
        <td className="px-4 py-2 border border-gray-400">{phoneNumber}</td>
        <td className="px-4 py-2 border border-gray-400">{linkedInProfile}</td>
        <td className="px-4 py-2 border border-gray-400">{company}</td>
        <td className="px-4 py-2 border border-gray-400">{position}</td>
        <td className="px-4 py-2 border border-gray-400">{notess}</td>
        <td className="px-4 py-2 border border-gray-400">
          {status}
          <div className="mb-4">
            <select
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              type="text"
              name="estatus"
              onChange={onChange}
              value={noted.estatus}
              minLength={5}
              required
              id="role-select"
            >
              <option value="follow_up">Follow Up</option>
              <option value="no_response">No Response</option>
              <option value="on_hold">On Hold</option>
              <option value="completed">Completed</option>
            </select>
          </div>

          <button
            onClick={handleClick}
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Done
          </button>
        </td>
        <td className="px-4 py-2 border border-gray-400">
          <button
            onClick={() => {
              deleteNote(note._id);
            }}
          >
            Delete
          </button>
        </td>
        <td className="px-4 py-2 border border-gray-400">
          <div>
            <button
              onClick={() => {
                f1();
                f2();
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Update Note
            </button>
          </div>
        </td>
        <td className="px-4 py-2 border border-gray-400">
          <div>
            <Message email={note.email} />
          </div>
        </td>
      </tr>
    </>
  );
};

export default NoteItem;
