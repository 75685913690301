import React, { useEffect, useRef, useState } from "react";
import NoteItem from "./NoteItem";
import AddNote from "./AddNote";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../context/NoteState";
import Excel from "./Excel";
import NoteItems from "./NoteItems";

const Notes = () => {
  let navigate = useNavigate();
  const { notes, getNotes, editNote } = useGlobalContext();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    } else {
      getNotes();
    }
  }, []);

  const [note, setNote] = useState({
    ename: "",
    eemail: "",
    ephoneNumber: "",
    elinkedInProfile: "",
    ecompany: "",
    eposition: "",
    enotess: "",
    estatus: "",
  });

  const updateNote = (currentNote) => {
    setNote({
      id: currentNote._id,
      ename: currentNote.name,
      eemail: currentNote.email,
      ephoneNumber: currentNote.phoneNumber,
      elinkedInProfile: currentNote.linkedInProfile,
      ecompany: currentNote.company,
      eposition: currentNote.position,
      enotess: currentNote.notess,
      estatus: currentNote.status,
    });
  };

  const handleClick = (e) => {
    console.log("Updating the note...", note);
    editNote(
      note.id,
      note.ename,
      note.eemail,
      note.ephoneNumber,
      note.elinkedInProfile,
      note.ecompany,
      note.eposition,
      note.enotess,
      note.estatus
    );
    alert("Updated Successfully");
  };

  const onChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="m-4 flex items-center justify-between">
        {/* Add a new client */}
        <AddNote />
        {/* Add a Excel data */}
        <Excel />
        {/* Update pop up */}
      </div>
      <div>
        {isOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-10">
            <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
            <div className="bg-white rounded-lg z-20 w-96">
              <div className="max-w-md mx-auto bg-white rounded-md shadow-md px-14 py-5">
                <button
                  onClick={togglePopup}
                  className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                  &times;
                </button>
                <form>
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      id="etitle"
                      name="ename"
                      onChange={onChange}
                      value={note.ename}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>

                    <input
                      type="email"
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      id="etitle"
                      name="eemail"
                      aria-describedby="emailHelp"
                      onChange={onChange}
                      value={note.eemail}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>

                    <input
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      type="text"
                      id="etitle"
                      name="ephoneNumber"
                      aria-describedby="emailHelp"
                      onChange={onChange}
                      value={note.ephoneNumber}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700"
                    >
                      linkedIn Profile
                    </label>
                    <input
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      type="text"
                      id="etitle"
                      name="elinkedInProfile"
                      onChange={onChange}
                      value={note.elinkedInProfile}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company
                    </label>
                    <input
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      type="text"
                      id="etitle"
                      name="ecompany"
                      onChange={onChange}
                      value={note.ecompany}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Position
                    </label>
                    <input
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      type="text"
                      id="etitle"
                      name="eposition"
                      aria-describedby="emailHelp"
                      onChange={onChange}
                      value={note.eposition}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Notes
                    </label>
                    <input
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      type="text"
                      id="etitle"
                      name="enotess"
                      aria-describedby="emailHelp"
                      onChange={onChange}
                      value={note.enotess}
                      minLength={5}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Status
                    </label>

                    <select
                      className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                      type="text"
                      name="estatus"
                      onChange={onChange}
                      value={note.estatus}
                      minLength={5}
                      required
                      id="role-select"
                    >
                      <option value="follow_up">Follow Up</option>
                      <option value="no_response">No Response</option>
                      <option value="on_hold">On Hold</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div>
                  <button
                    onClick={togglePopup}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Close
                  </button>

                  <button
                    onClick={handleClick}
                    type="button"
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  >
                    Update Note
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Display Client */}
      <div className="container mx-auto p-4">
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Name
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Email
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Phone Number
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  LinkedIn Profile
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Company
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Position
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Notes
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Status
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Delete
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  Update
                </th>
                <th className="px-4 py-2 bg-gray-200 border border-gray-400">
                  message
                </th>
              </tr>
            </thead>
            <tbody>
              {notes?.map((note, index) => {
                return (
                  <NoteItem
                    index={index}
                    updateNote={updateNote}
                    note={note}
                    togglePopup={togglePopup}
                    onChange={onChange}
                  />
                );
              })}
            </tbody>
          </table>

          <NoteItems />
        </div>
      </div>
    </>
  );
};

export default Notes;
