import React, { useState } from "react";
import Notes from "../Notes";

const Home = () => {

  return (
    <div>
      <Notes />
    </div>
  );
};

export default Home;
